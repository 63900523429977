<template>
   <div class="">
       <div class="form-data mentor">
           <div class="title">멘토알림</div>
           <div class="value">
               <el-radio-group v-model="hasMentor" @change="changeHasMentor()">
                   <el-radio :label="1">사용</el-radio>
                   <el-radio :label="0">사용안함</el-radio>
               </el-radio-group>
               <div  v-if="hasMentor === 1">
                   <button @click="openMentorSel()" class="mentor-sel-btn">멘토지정</button>
                   <div class="list">
                       <div v-for="(mentor, index) in mentorList" :key="`selMentor${index}`" class="c_mentor">
                           <div class="profile">
                               <img :src="returnProfileImage(mentor)">
                           </div>
                           <div class="info">
                               <div class="i_top">
                                   {{mentor.mb_name}}
                                   <span>{{mentor.mb_job}}</span>
                               </div>
                               <div class="company">{{mentor.mb_belong}}</div>
                           </div>
                       </div>
                       <div class="caption" v-show="mentorList.length > 0">게시글 작성 후 지정된 멘토는 변경이 불가능합니다</div>
                   </div>
               </div>

               </div>
       </div>
   </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import BoardValidator from "@/mixins/validators/BoardValidator";
    import util from "@/mixins/util";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: "BoardWriteMentorLayout",
        mixins: [BoardValidator, imageOption],
        inject: ['boardWriteSetData'],
        props: {
            boardData: {
                default: {}
            },
        },
        data() {
            return {
                hasMentor: 0,
                mentorList: [],
            }
        },
        computed: {},
        created() {
            EventBus.$on('selectMentor', this.selectMentor)
        },
        mounted() {

        },
        beforeDestroy() {
            EventBus.$off('selectMentor')
        },
        methods: {
            parentSetData(type, value) {
                // this[type] = value;
                this.boardWriteSetData(type, value);
            },
            changeHasMentor() {
                // this.hasMentor = value;
                this.mentorList = [];
                this.parentSetData('mentor', []);
            },
            openMentorSel() {
                EventBus.$emit('openMentorSelModal', this.mentorList)
            },
            selectMentor(selMentor) {
                if(Array.isArray(selMentor)) {
                    this.mentorList = selMentor;
                }
                if(this.mentorList.length === 0) {
                    this.changeHasMentor('0')
                }else{
                    let mentors = [];
                    util.Array.each(this.mentorList,  (obj, index, array) => {
                        mentors.push(obj.mb_no);
                    })
                    this.parentSetData('mentor', mentors)
                }
            },
            returnProfileImage(member) {
                return member.hasOwnProperty('Profile') && member.Profile.length > 0 ? `${member.Profile[0].org_url}?${this.setImageOptions(70, 70, 'png')}` : ''
            },
        },
    }
</script>

<style scoped>

</style>